
import { CategorySelectionTimeout } from '@/config/app';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
	emits: ['timedOut'],
	setup(_, { emit }) {
		const time = ref(CategorySelectionTimeout);
		let active = false;

		onMounted(() => {
			active = true;
			let initTs: number = null;
			const timerLoop = (ms: number) => {
				if (!active) return;
				if (initTs === null) initTs = ms;

				const diff = ms - initTs;

				time.value = CategorySelectionTimeout - Math.floor(diff / 1000);

				if (time.value <= 0) {
					emit('timedOut');
					return;
				}

				window.requestAnimationFrame(timerLoop);
			};

			window.requestAnimationFrame(timerLoop);
		});

		onUnmounted(() => (active = false));

		return { time };
	}
});
