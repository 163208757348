
import { EndBackground } from '@/config/backgrounds';
import { BackgroundService } from '@/services';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
	emits: ['invoked'],
	setup() {
		onMounted(() => BackgroundService.set(EndBackground));

		const reload = () => window.location.reload();

		return { reload };
	}
});
