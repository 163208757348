
import { Player, Scenario } from '@/models';
import { BackgroundService } from '@/services';
import { computed, defineComponent, onMounted } from 'vue';
import ScenarioText from '@/views/shared/ScenarioText.vue';
import { useSignalR } from '@quangdao/vue-signalr';
import { SelectScenario, SkipScenario, ToggleHand } from '@/config/actions';

export type ScenarioDisplayMode = 'select' | 'discuss';
export type DiscussionState = 'idle' | 'raised' | 'answered';

export default defineComponent({
	components: { ScenarioText },
	props: {
		scenario: {
			type: Object as () => Scenario,
			default: null
		},
		player: {
			type: Object as () => Player,
			default: null
		},
		mode: {
			type: String as () => ScenarioDisplayMode,
			default: null
		}
	},
	setup(props) {
		const signalr = useSignalR();
		const raised = computed(() => props.player.handRaised);
		const answered = computed(() => props.player.questionAnswered);
		const state = computed<DiscussionState>(() => {
			if (answered.value) return 'answered';
			return raised.value ? 'raised' : 'idle';
		});

		onMounted(() => {
			BackgroundService.set(props.scenario.category.imageUrl);
		});

		const skip = () => signalr.send(SkipScenario);
		const select = () => signalr.send(SelectScenario, props.scenario.id);
		const toggleHand = () => signalr.send(ToggleHand);

		return { state, skip, select, toggleHand };
	}
});
