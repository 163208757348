import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55b57037"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "screen screen-overlay" }
const _hoisted_2 = { class: "loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.message.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.message.message), 1)
    ])
  ]))
}