
import { DefaultBackground } from '@/config/backgrounds';
import { LoadingMessage } from '@/models';
import { BackgroundService } from '@/services';
import { onMounted, defineComponent } from 'vue';

export default defineComponent({
	props: {
		message: {
			type: Object as () => LoadingMessage,
			default: null
		}
	},
	setup() {
		onMounted(() => {
			BackgroundService.set(DefaultBackground);
		});
	}
});
