
import { DefaultBackground } from '@/config/backgrounds';
import { LoadingMessage } from '@/models';
import { BackgroundService } from '@/services';
import { onMounted, defineComponent } from 'vue';

export default defineComponent({
	props: {
		message: {
			type: Object as () => LoadingMessage,
			default: null
		}
	},
	emits: ['ready'],
	setup(_, { emit }) {
		onMounted(() => {
			BackgroundService.set(DefaultBackground);
		});

		const ready = () => emit('ready');

		return { ready };
	}
});
