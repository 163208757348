import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f26a90a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "screen screen-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_categories_list = _resolveComponent("categories-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_categories_list, { onSelected: _ctx.select }, null, 8, ["onSelected"])
  ]))
}