
import JoinScreen from './screens/Join.vue';
import LoadingScreen from './screens/Loading.vue';
import PrepScreen from './screens/Prep.vue';
import CategoriesScreen from './screens/Categories.vue';
import ScenarioScreen, { ScenarioDisplayMode } from './screens/Scenario.vue';
import EndScreen from './screens/End.vue';
import { computed, defineComponent, ref } from 'vue';
import { LoadingMessage, Player, Scenario } from '@/models';
import { useSignalR } from '@quangdao/vue-signalr';
import {
	GameOver,
	PlayerSelected,
	PlayerUpdated,
	ScenarioPrepared,
	ScenarioSelected,
	TimeOut
} from '@/config/actions';
import { RETURN_ID } from '@/config/local-storage-keys';
import RoundTimer from './components/RoundTimer.vue';

type States = 'idle' | 'start' | 'prep' | 'categories' | 'scenario' | 'end';

export default defineComponent({
	components: {
		JoinScreen,
		LoadingScreen,
		PrepScreen,
		CategoriesScreen,
		ScenarioScreen,
		EndScreen,
		RoundTimer
	},
	setup() {
		const signalr = useSignalR();
		const state = ref<States>('start');
		const player = ref<Player>(null);
		const scenario = ref<Scenario>(null);
		const loadingMessage = ref<LoadingMessage>({
			title: 'Please Hold',
			message: 'your adventure is about to begin'
		});
		const scenarioMode = ref<ScenarioDisplayMode>('select');

		const isSelecting = computed(() => {
			return (
				state.value === 'prep' ||
				state.value === 'categories' ||
				(state.value === 'scenario' && scenarioMode.value === 'select')
			);
		});

		const setPlayer = (result: Player) => {
			player.value = result;

			window.localStorage.setItem(RETURN_ID, result.id.toString());

			state.value = 'idle';
		};

		const playerReady = () => (state.value = 'categories');

		signalr.on(PlayerSelected, selected => {
			if (player.value.id === selected.id) {
				state.value = 'prep';
			} else {
				loadingMessage.value = {
					title: 'Please Hold',
					message: 'while the next player selects a question'
				};
				state.value = 'idle';
			}
		});

		signalr.on(PlayerUpdated, updated => {
			if (player.value.id === updated.id) {
				player.value = updated;
			}
		});

		signalr.on(ScenarioPrepared, found => {
			state.value = 'scenario';
			scenarioMode.value = 'select';
			scenario.value = found;
		});

		signalr.on(ScenarioSelected, found => {
			state.value = 'scenario';
			scenarioMode.value = 'discuss';
			scenario.value = found;
		});

		signalr.on(GameOver, () => (state.value = 'end'));

		const timeOut = () => signalr.send(TimeOut);

		return {
			state,
			scenario,
			player,
			loadingMessage,
			scenarioMode,
			isSelecting,
			setPlayer,
			playerReady,
			timeOut
		};
	}
});
