
import { defineComponent } from 'vue';
import CategoriesList from '@/views/shared/CategoriesList.vue';
import { Category } from '@/models';
import { useSignalR } from '@quangdao/vue-signalr';
import { SelectCategory } from '@/config/actions';

export default defineComponent({
	components: {
		CategoriesList
	},
	setup() {
		const signalr = useSignalR();

		const select = (category: Category) => {
			signalr.send(SelectCategory, category.id);
		};

		return { select };
	}
});
