import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_screen = _resolveComponent("loading-screen")!
  const _component_join_screen = _resolveComponent("join-screen")!
  const _component_prep_screen = _resolveComponent("prep-screen")!
  const _component_categories_screen = _resolveComponent("categories-screen")!
  const _component_scenario_screen = _resolveComponent("scenario-screen")!
  const _component_end_screen = _resolveComponent("end-screen")!
  const _component_round_timer = _resolveComponent("round-timer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state === 'idle')
      ? (_openBlock(), _createBlock(_component_loading_screen, {
          key: 0,
          message: _ctx.loadingMessage
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'start')
      ? (_openBlock(), _createBlock(_component_join_screen, {
          key: 1,
          onJoined: _ctx.setPlayer
        }, null, 8, ["onJoined"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'prep')
      ? (_openBlock(), _createBlock(_component_prep_screen, {
          key: 2,
          onReady: _ctx.playerReady
        }, null, 8, ["onReady"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'categories')
      ? (_openBlock(), _createBlock(_component_categories_screen, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.state === 'scenario')
      ? (_openBlock(), _createBlock(_component_scenario_screen, {
          key: 4,
          scenario: _ctx.scenario,
          player: _ctx.player,
          mode: _ctx.scenarioMode
        }, null, 8, ["scenario", "player", "mode"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'end')
      ? (_openBlock(), _createBlock(_component_end_screen, { key: 5 }))
      : _createCommentVNode("", true),
    (_ctx.isSelecting)
      ? (_openBlock(), _createBlock(_component_round_timer, {
          key: 6,
          onTimedOut: _ctx.timeOut,
          class: "action-left"
        }, null, 8, ["onTimedOut"]))
      : _createCommentVNode("", true)
  ], 64))
}