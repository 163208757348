
import { defineComponent, reactive } from 'vue';
import LogoSVG from '@/assets/on-the-edge-logo.svg?inline';
import { JoinGameModel } from '@/models';
import { JoinSession, RejoinSession } from '@/config/actions';
import { useSignalR } from '@quangdao/vue-signalr';
import { RETURN_ID } from '@/config/local-storage-keys';

export default defineComponent({
	components: {
		LogoSVG
	},
	setup(_, { emit }) {
		var signalr = useSignalR();
		const rejoinId = +window.localStorage.getItem(RETURN_ID);

		const model = reactive<JoinGameModel>({
			name: '',
			roomCode: ''
		});

		const joinSession = async () => {
			const player = await signalr.invoke(JoinSession, model);

			if (!player) return;

			emit('joined', player);
		};

		const rejoinSession = async () => {
			const player = await signalr.invoke(RejoinSession, rejoinId);

			if (!player) return;

			emit('joined', player);
		};

		return { model, rejoinId, joinSession, rejoinSession };
	}
});
